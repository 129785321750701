@tailwind base;
@tailwind components;
@tailwind utilities;

$min_width: 1024px;
$max_width: 1920px;
$min_font: 10px;
$max_font: 18px;

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@font-face {
  font-family: 'circular';
  font-style: normal;
  font-weight: 400;
  src: url('../font/CircularXXWeb-Book.woff2') format('woff2'),
  url('../font/CircularXXWeb-Book.woff') format('woff');
}

@font-face {
  font-family: 'circular';
  font-style: normal;
  font-weight: 700;
  src: url('../font/CircularXXWeb-Bold.woff2') format('woff2'),
  url('../font/CircularXXWeb-Bold.woff') format('woff');
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

